import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import Cookies from "./helpers/Cookies";
import Constants from "./common/Constants";

// Basic component with logout button
const OktaLogout = () => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    // Delete the custom cookies
    Cookies.setCookie(Constants.DENTSU_COOKIE, "", null, true);
    Cookies.setCookie(Constants.APP_LOGIN_KEY, "", null, true);
    Cookies.setCookie(Constants.APP_LOGIN_KEY_APPEND, "", null, true);
    Cookies.setCookie(Constants.APP_LOGIN_USER, "", null, true);
    oktaAuth.tokenManager.clear();
    // Clear localStorage in case there is any
    localStorage.clear();
  };

  return (
    <a
      onClick={logout}
      alt="Logout User"
      className="dropdown-item"
      href=" "
      title="Logout"
    >
      <i className="fas fa-power-off" />
      {` `}
      Logout
    </a>
  );
};

export default OktaLogout;
