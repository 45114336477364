import React from "react";
import PropTypes from "prop-types";

const ChangeLog = ({ versionNumber }) => (
  <div>
    <h5>{`Current Version: ${versionNumber}`}</h5>
    <hr />
    <h4>Version 1.0.0</h4>
    <h5>New Features</h5>
    <ul>
      <li>Initial Version</li>
    </ul>
    <hr />
  </div>
);

ChangeLog.propTypes = {
  versionNumber: PropTypes.string.isRequired
};

export default ChangeLog;
