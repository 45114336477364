import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";

import "./../../styles/user-manager.scss";
import SelectBox from "../shared/SelectBox";
import Constants from "../../common/Constants";
import api from "../../services/BaseApi";

const UserForm = ({
  handleSubmit,
  initialValues,
  heading,
  onModalClose,
  groupData
}) => {
  const UserSchema = Yup.object().shape({
    displayName: Yup.string().required("Please enter a name"),
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter an email"),
    enabled: Yup.boolean(),
    groups: Yup.array()
  });

  return (
    <>
      <div className="display-flex m-2">
        <h5>{heading}</h5>
      </div>
      <div className="form-horizontal m-2">
        <Formik
          initialValues={initialValues}
          validationSchema={UserSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, dirty, isValid }) => {
            return (
              <Form>
                <div className="form-group mt-3">
                  <label htmlFor="name">Name :</label>
                  <Field
                    type="text"
                    name={Constants.FORM_FIELDS.name}
                    className="form-control p-0"
                  />
                  <div className="mt-1 error-message">
                    <ErrorMessage name={Constants.FORM_FIELDS.name} />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="email">Email :</label>
                  <Field
                    type="email"
                    name={Constants.FORM_FIELDS.email}
                    className="form-control p-0"
                  />
                  <div className="mt-1 error-message">
                    <ErrorMessage name={"email"} />
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="email">Permissions :</label>
                  <SelectBox
                    id="permissionId"
                    name={Constants.FORM_FIELDS.groups}
                    placeholder="Select groups"
                    defaultValue={values.groups}
                    className="form-control p-0"
                    options={groupData}
                    onSelectHandler={(value) => setFieldValue("groups", value)}
                    isMultiSelect
                  />
                  <div className="mt-1 error-message">
                    <ErrorMessage name={Constants.FORM_FIELDS.groups} />
                  </div>
                </div>
                <div className=" mt-5">
                  <label>Status :</label>
                  <Field type="checkbox" name={Constants.FORM_FIELDS.status} />
                </div>
                <div className="form-group pt-3">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={!dirty || !isValid}
                  >
                    Submit
                  </button>
                  <button
                    className="btn btn-default"
                    type="button"
                    onClick={onModalClose}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default UserForm;

UserForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
    enabled: PropTypes.bool
  })
};

UserForm.defaultProps = {
  initialValues: {
    displayName: "",
    email: "",
    groups: [],
    enabled: true
  }
};
