import React from "react";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import Constants from "../../common/Constants";
import api from "../../services/BaseApi";

const ExportLink = ({ setIsExportDone }) => {
  const exportToExcel = async () => {
    try {
      setIsExportDone(false);
      const result = await api.exportPublishedAudiences();
      if (result && result.success) {
        setIsExportDone(true);
        const link = document.createElement("a");
        link.setAttribute("href", result.link);
        link.setAttribute("role", "export");
        link.setAttribute("download", "audience_usage_summary.csv");
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setIsExportDone(true);
        toast.error(
          "There was an error in exporting the data",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setIsExportDone(true);
      toast.error(
        `There was an error in exporting the data - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  return (
    <>
      <button className="btn btn-primary" onClick={exportToExcel} type="button">
        Export to Excel
      </button>
    </>
  );
};

ExportLink.propTypes = {
  setIsExportDone: PropTypes.func.isRequired
};

export default ExportLink;
