import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";

import UserForm from "./UserForm";
import api from "../../services/BaseApi";
import Constants from "../../common/Constants";

const CreateUserModal = ({
  isModalOpen,
  onModalClose,
  refreshUserTable,
  groupData
}) => {
  const handleSubmit = async (user, { resetForm }) => {
    const result = (await api.createUser(user)) || {};
    if (result.status === "success") {
      toast.success("Record saved successfully", Constants.TOAST_OPTIONS);
      resetForm();
      onModalClose();
      refreshUserTable();
    } else {
      toast.error(
        "There was a problem in saving the data, please re-confirm the record.",
        Constants.TOAST_OPTIONS
      );
    }
  };

  return (
    <div className="container">
      <Modal
        id="userManagerModal"
        role="dialog"
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        contentLabel="Create User"
        className="modalcontent"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <UserForm
          heading={"Create User"}
          handleSubmit={handleSubmit}
          onModalClose={onModalClose}
          groupData={groupData}
        />
      </Modal>
    </div>
  );
};

export default CreateUserModal;
