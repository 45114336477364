import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import UserTable from "./UserTable";
import api from "./../../services/BaseApi";
import Constants from "./../../common/Constants";
import ConfirmDeleteUserModal from "./ConfirmDeleteUserModal";
import CreateUserModal from "./CreateUserModal";
import EditUserModal from "./EditUserModal";

const UserManager = () => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [uiState, setUIState] = useState(Constants.UI_STATE_SUCCESS);
  const [userTableData, setUserTableData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [usersSearchPayload, setUsersSearchPayload] = useState("");
  const [page, setPage] = useState("");

  const clearSearchInput = () => {
    setUsersSearchPayload("");
    document.getElementById("searchUsers").value = "";
  };

  const debouncedHandleAudienceSearchTermChange = debounce(
    (search) => setUsersSearchPayload(search.toLowerCase()),
    500
  );

  const routeChange = (page) => {
    if (page === "edit") {
      navigate(`/user-manager/edit`, { state: selectedRow });
    } else {
      navigate(`/user-manager/new`);
    }
  };

  const onDeleteUser = async (userId) => {
    const result = (await api.deleteUser(userId)) || {};
    if (result.status === "success") {
      setPage("");
      toast.success("Record Deleted successfully", Constants.TOAST_OPTIONS);
      refreshUserTable();
    } else {
      toast.error(
        "There was a problem in Deleting the data, please re-confirm the record.",
        Constants.TOAST_OPTIONS
      );
    }
  };

  const refreshUserTable = () => {
    setTimeout(() => {
      getUsers();
    }, 500);
  };

  const getUsers = async () => {
    try {
      setUIState(Constants.UI_STATE_LOADING);
      const input = {
        searchTerm: "",
        groupSearch: ""
      };
      const res = await api.getUsers(input);

      if (res.users) {
        if (res.users.length) {
          setUIState(Constants.UI_STATE_SUCCESS);
          setUserTableData(res.users);
        } else {
          setUIState(Constants.UI_STATE_EMPTY);
        }
      } else {
        setUIState(Constants.UI_STATE_ERROR);
        toast.error(
          "There was an error in fetching users",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setUIState(Constants.UI_STATE_ERROR);
      // Ignore the exception, this means we did not get the data
      toast.error(
        `There was an error in fetching users - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getGroups = async () => {
    try {
      const res = await api.getGroups();
      if (res && res.groups) {
        const groups = res.groups.map((group) => ({
          label: group,
          value: group
        }));
        setGroupData(groups);
      } else {
        toast.error(
          "There was an error in fetching groups",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching groups - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  useEffect(() => {
    getUsers();
    getGroups();
  }, []);

  const filteredData = useMemo(() => {
    return userTableData.filter((user) =>
      `${user.name} ${user.email}`.toLowerCase().includes(usersSearchPayload)
    );
  }, [userTableData, usersSearchPayload]);

  return (
    <div className="audience-list-page audiencesClass full-height">
      <div className="audience-list-page__action-bar">
        <span className="display-flex audience-list-page__action-bar__left">
          <h5 className="audience-list-page__action-bar__title dark-gray">
            List of Merkury Usage users
          </h5>
        </span>
      </div>
      <div className="card audience-list-page__table-wrapper">
        <span className="mt-2">
          <button
            className="btn btn-primary float-right"
            type="button"
            disabled={!selectedRow}
            onClick={() => setPage(Constants.DELETE_USER)}
          >
            Delete
          </button>
          <button
            className="btn btn-primary float-right"
            type="button"
            onClick={() => setPage(Constants.EDIT_USER)}
            disabled={!selectedRow}
          >
            Edit
          </button>
          <button
            className="btn btn-primary float-right"
            type="button"
            onClick={() => setPage(Constants.CREATE_USER)}
          >
            Create
          </button>
          <span className="search-input-container">
            <input
              id="searchUsers"
              type="text"
              className="form-control rounded-input"
              placeholder="Search..."
              onChange={(e) =>
                debouncedHandleAudienceSearchTermChange(e.target.value)
              }
            />
            <span className="search-icons">
              {usersSearchPayload.length > 0 ? (
                <i
                  className="fas fa-times clear-icon"
                  onClick={clearSearchInput}
                ></i>
              ) : (
                <i className="fas fa-search"></i>
              )}
            </span>
          </span>
        </span>
        <hr />
        <UserTable
          data={filteredData}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          uiState={uiState}
        />
      </div>
      {selectedRow && (
        <>
          <ConfirmDeleteUserModal
            user={selectedRow}
            isModalOpen={page === Constants.DELETE_USER}
            onModalClose={() => setPage("")}
            onModalSubmit={onDeleteUser}
          />
          <EditUserModal
            selectedUser={selectedRow}
            isModalOpen={page === Constants.EDIT_USER}
            onModalClose={() => setPage("")}
            refreshUserTable={refreshUserTable}
            groupData={groupData}
          />
        </>
      )}

      <CreateUserModal
        isModalOpen={page === Constants.CREATE_USER}
        onModalClose={() => setPage("")}
        refreshUserTable={refreshUserTable}
        groupData={groupData}
      />
    </div>
  );
};

export default UserManager;
