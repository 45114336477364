import Cookies from "../helpers/Cookies";
import Constants from "../common/Constants";

/**
 * Generic HTTP function that performs both GET and POST
 */
async function baseRequest(verb, apiEndpoint, data = null) {
  // construct the request
  const oktaKeyOne = Cookies.getCookie(Constants.APP_LOGIN_KEY);
  const oktaKeyTwo = Cookies.getCookie(Constants.APP_LOGIN_KEY_APPEND);
  const oktaKey = oktaKeyOne + oktaKeyTwo;

  const headers = {
    Authorization: oktaKey,
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
    "Cache-Control": "no-store, no-cache"
  };
  if (process.env.DISABLE_HEADERS) {
    delete headers.Pragma;
    delete headers["Cache-Control"];
  }
  let request = {
    method: verb,
    headers
  };

  // Set the body if data is present
  if (data) {
    request.body = JSON.stringify(data);
  }

  let obj = { ...window.userSession };
  delete obj.lambdaRouting; // ensure no routing for UAT and PROD
  const sessionObject = obj;
  request.headers[Constants.DENTSU_COOKIE] = JSON.stringify(obj);

  // store it...
  if (sessionObject && sessionObject.userId) {
    // Set the cookie value
    Cookies.setCookie(
      Constants.DENTSU_COOKIE,
      request.headers[Constants.DENTSU_COOKIE],
      2
    );
  }

  // Call the API to get the response
  const response = await fetch(apiEndpoint, request);
  const payload = await response.json();

  if (!response.ok) {
    throw new Error(payload.error || "Unexpected Error");
  }

  return payload;
}

const postRequest = (path, data) =>
  baseRequest("POST", Constants.API_ROOT_URL + path, data);
const getRequest = (path) => baseRequest("GET", Constants.API_ROOT_URL + path);

async function getAudiences(input) {
  return await postRequest("/getPublishedAudiences", input);
}

async function getClients() {
  const requestObj = {
    filterCol: Constants.COL_MERKLE_ADVERTISER_NAME
  };
  return await postRequest("/getFilterList", requestObj);
}

async function getConnections() {
  const requestObj = {
    filterCol: Constants.COL_MERKLE_CONNECTION_NAME
  };
  return await postRequest("/getFilterList", requestObj);
}

async function exportPublishedAudiences() {
  return await postRequest("/exportPublishedAudiences");
}

// User Management API Methods
async function getUsers(input) {
  return await postRequest("/getUsers", input);
}

async function getGroups() {
  return await postRequest("/getGroups");
}

async function createUser(userData) {
  // construct the object with form values
  const requestObj = {
    user: userData
  };
  return postRequest("/createUser", requestObj);
}

async function updateUser(userData) {
  // construct the object with form values
  const requestObj = {
    user: userData
  };
  return postRequest("/updateUser", requestObj);
}

async function deleteUser(userId) {
  // construct the object with form values
  const requestObj = {
    userId: userId
  };
  return postRequest("/deleteUser", requestObj);
}

// Validate Session method
async function validateSession(sessionKey, emailAddress) {
  return postRequest("/validateSession", { sessionKey, emailAddress });
}

export default {
  getAudiences,
  getConnections,
  getClients,
  exportPublishedAudiences,
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getGroups,
  validateSession
};
