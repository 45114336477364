/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import Constants from "../common/Constants";
import BackdropLoader from "./shared/BackdropLoader";
import "../styles/audiences-table.scss";

const AudiencesTable = ({
  uiState,
  originalData,
  totalPageCount,
  totalCount,
  setTablePageSize,
  setTablePageOffset,
  setTableSort
}) => {
  // Column Config
  const columns = React.useMemo(
    () => [
      {
        Header: "Publish Date/Time",
        accessor: Constants.COL_PUBLISH_DATE
      },
      {
        Header: "Merkury Adv. Name",
        accessor: Constants.COL_MERKLE_ADVERTISER_NAME
      },
      {
        Header: "Merkury Adv. ID",
        accessor: Constants.COL_MERKLE_ADVERTISER_ID
      },
      {
        Header: "Published Aud. Name",
        accessor: Constants.COL_AUDIENCE_NAME
      },
      {
        Header: "Published Aud. ID",
        accessor: Constants.COL_AUDIENCE_ID
      },
      {
        Header: "Published Aud. Version",
        accessor: Constants.COL_AUDIENCE_VERSION
      },
      {
        Header: "Merkury Connection Name",
        accessor: Constants.COL_MERKLE_CONNECTION_NAME
      },
      {
        Header: "Merkury Connection Id",
        accessor: Constants.COL_MERKLE_CONNECTION_ID
      },
      {
        Header: "Merkury Connection Account + ID",
        accessor: Constants.COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID,
        disableSortBy: true
      },
      {
        Header: "Saved Audience Size",
        accessor: Constants.COL_SAVED_AUDIENCE_SIZE
      },
      {
        Header: "Published Audience Size",
        accessor: Constants.COL_PUBLISHED_AUDIENCE_SIZE
      }
    ],
    []
  );

  const centerAlignedColumns = [
    Constants.COL_MERKLE_CONNECTION_ID,
    Constants.COL_AUDIENCE_ID
  ];
  const notSortableColumns = [Constants.COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID];

  // Table Config
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns,
      data: originalData,
      autoResetPage: false,
      disableSortRemove: true,
      autoResetSortBy: false,
      manualSortBy: true,
      manualPagination: true,
      pageCount: totalPageCount,
      initialState: {
        pageIndex: 0,
        sortBy: [{ id: Constants.COL_PUBLISH_DATE, desc: true }]
      }
    },
    useSortBy,
    usePagination
  );

  // Call the parent method in case sortBy is updated, which in turn calls the
  // Audience fetch method by sending the sort params
  useEffect(() => {
    setTableSort(sortBy);
  }, [sortBy]);

  const getCellContents = (cell) => {
    switch (cell.column.Header) {
      case Constants.COL_AUDIENCE_NAME:
        return (
          <span className="audience-name__cell">{cell.render("Cell")}</span>
        );
      default:
        return cell.render("Cell");
    }
  };

  function getHeaderClassName(header) {
    switch (header) {
      case Constants.COL_PUBLISH_DATE:
        return "col-publish-date";
      case Constants.COL_MERKLE_ADVERTISER_NAME:
        return "col-adv-name";
      case Constants.COL_MERKLE_ADVERTISER_ID:
        return "col-adv-id";
      case Constants.COL_AUDIENCE_ID:
        return "col-audience-id";
      case Constants.COL_AUDIENCE_NAME:
        return "col-audience-name";
      case Constants.COL_MERKLE_CONNECTION_ID:
        return "col-conn-id";
      case Constants.COL_MERKLE_CONNECTION_NAME:
        return "col-conn-name";
      case Constants.COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID:
        return "col-conn-account-id-name";
      default:
        return "";
    }
  }

  function getCellClassName(header) {
    switch (header) {
      case Constants.COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID:
        return "sconn-account-id-name-cell";
      default:
        return "";
    }
  }

  const getTableBodyTemplate = () => {
    switch (uiState) {
      case Constants.UI_STATE_LOADING:
        return (
          <tr>
            <td colSpan={12} className="pos-relative loader-wrapper">
              <BackdropLoader />
            </td>
          </tr>
        );
      case Constants.UI_STATE_EMPTY:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">
                No Audiences available
              </h5>
            </td>
          </tr>
        );
      case Constants.UI_STATE_ERROR:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">
                Error fetching Audiences
              </h5>
            </td>
          </tr>
        );
      default:
        if (rows.length === 0) {
          return (
            <tr>
              <td colSpan={12}>
                <h5 className="placeholder text-center">
                  No Audiences available
                </h5>
              </td>
            </tr>
          );
        }
        return rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  className={`${getCellClassName(cell.column.Header)}`}
                  style={{
                    textAlign: centerAlignedColumns.includes(cell.column.Header)
                      ? "center"
                      : "left"
                  }}
                >
                  {getCellContents(cell)}
                </td>
              ))}
            </tr>
          );
        });
    }
  };

  return (
    <>
      <div className="table-container">
        <table
          key="audiences-table"
          className="audiences-table"
          data={originalData}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      textAlign: "center",
                      cursor: !notSortableColumns.includes(column.Header)
                        ? "pointer"
                        : "auto"
                    }}
                    className={`${getHeaderClassName(column.Header)}`}
                  >
                    {column.render("Header")}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="fas sort-button fa-sort-down" />
                      ) : (
                        <i className="fas sort-button fa-sort-up" />
                      )
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {getTableBodyTemplate()}
            <tr>
              <td colSpan="10000">
                {`Showing ${page.length} of ~${totalCount} results`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          type="button"
          className="btn-small btn-secondary"
          onClick={() => {
            gotoPage(0);
            setTablePageOffset(0);
          }}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>{" "}
        <button
          type="button"
          className="btn-small btn-secondary"
          onClick={() => {
            previousPage();
            setTablePageOffset(pageIndex - 1);
          }}
          disabled={!canPreviousPage}
        >
          {"<"}
        </button>{" "}
        <button
          type="button"
          className="btn-small btn-secondary"
          onClick={() => {
            nextPage();
            setTablePageOffset(pageIndex + 1);
          }}
          disabled={!canNextPage}
        >
          {">"}
        </button>{" "}
        <button
          type="button"
          className="btn-small btn-secondary"
          onClick={() => {
            gotoPage(pageCount - 1);
            setTablePageOffset(pageCount);
          }}
          disabled={!canNextPage}
        >
          {">>"}
        </button>
        <div className="ml-2 mt-2">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
            | Go to page:
          </span>
          <input
            type="number"
            className="ml-2"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNum = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageNum);
              setTablePageOffset(pageNum + 1);
            }}
            style={{ width: "80px", height: "18px" }}
          />
          <select
            value={pageSize}
            className="ml-2"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setTablePageSize(Number(e.target.value));
            }}
          >
            {[50, 100, 200].map((pSize) => (
              <option key={pSize} value={pSize}>
                Show {pSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

AudiencesTable.propTypes = {
  uiState: PropTypes.string.isRequired,
  originalData: PropTypes.arrayOf(PropTypes.shape).isRequired,
  setTablePageSize: PropTypes.func.isRequired,
  setTableSort: PropTypes.func.isRequired,
  setTablePageOffset: PropTypes.func.isRequired,
  totalPageCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired
};

export default AudiencesTable;
