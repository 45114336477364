import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";

import UserForm from "./UserForm";
import api from "../../services/BaseApi";
import Constants from "../../common/Constants";

const EditUserModal = ({
  selectedUser,
  isModalOpen,
  onModalClose,
  refreshUserTable,
  groupData
}) => {
  const enabled = selectedUser.status === "active";

  const handleSubmit = async (user, obj) => {
    if (!user.userId) {
      toast.error("Please select a user.", Constants.TOAST_OPTIONS);
      return false;
    }
    let groupAdd = user.groups;
    let groupDelete = [];

    if (selectedUser.groups && selectedUser.groups.length > 0) {
      groupAdd = user.groups.filter(
        (item) => !selectedUser.groups.includes(item)
      );
      groupDelete = selectedUser.groups.filter(
        (item) => !user.groups.includes(item)
      );
    }
    const result =
      (await api.updateUser({ ...user, groupAdd, groupDelete })) || {};
    if (result.status === "success") {
      toast.success("Record updated successfully", Constants.TOAST_OPTIONS);
      onModalClose();
      refreshUserTable();
    } else {
      toast.error(
        "There was a problem in updating the data, please re-confirm the record.",
        Constants.TOAST_OPTIONS
      );
    }
  };

  return (
    <div className="container">
      <Modal
        id="editUserModal"
        role="dialog"
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        contentLabel="Edit User"
        className="modalcontent"
        overlayClassName="modaloverlay"
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
      >
        <UserForm
          heading={"Edit User"}
          handleSubmit={handleSubmit}
          onModalClose={onModalClose}
          initialValues={{ ...selectedUser, enabled }}
          groupData={groupData}
        />
      </Modal>
    </div>
  );
};

export default EditUserModal;
