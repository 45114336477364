import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";

import AudiencesTable from "./AudiencesTable";
import "../styles/audience-list-page.scss";
import api from "../services/BaseApi";
import Constants from "../common/Constants";
import ProgressOverlay from "./shared/ProgressOverlay";
import SelectBox from "./shared/SelectBox";
import ExportLink from "./shared/ExportLink";

/**
 * Main Page it displays all the published audiences that are available
 */
const AudienceListPage = () => {
  const [audiencesTableData, setAudiencesTableData] = useState([]);
  const [totalAudienceCount, setTotalAudienceCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const [clientData, setClientData] = useState([]);
  const [connectionData, setConnectionData] = useState([]);

  const [audienceSearchPayload, setAudienceSearchPayload] = useState("");
  const [clientSearchPayload, setClientSearchPayload] = useState([]);
  const [connectionSearchPayload, setConnectionSearchPayload] = useState("");

  const [pageOffset, setTablePageOffset] = useState(0);
  const [pageSize, setTablePageSize] = useState(50);
  const [tableSort, setTableSort] = useState([
    { id: Constants.COL_PUBLISH_DATE, desc: true }
  ]);

  const [isExportDone, setIsExportDone] = useState(true);

  const [isChangingClient /* , setChangingClient */] = useState(false);
  const [changeMessage /* , setChangeMessage */] = useState(<></>);

  const appRef = useRef(); // Create a ref object

  // Store the reference to the table to be used to access state from callback methods
  const audiencesTableDataStateRef = useRef();
  audiencesTableDataStateRef.current = audiencesTableData;

  const [uiState, setUIState] = useState(Constants.UI_STATE_SUCCESS);

  const clearSearchInput = () => {
    setAudienceSearchPayload("");
    document.getElementById("searchAudiences").value = "";
  };

  const debouncedHandleAudienceSearchTermChange = debounce(
    (search) => setAudienceSearchPayload(search),
    500
  );

  const getClients = async () => {
    try {
      const clientResult = await api.getClients();
      clientResult.push(Constants.BLANK);
      if (clientResult) {
        const clients = clientResult.map(({ ADVERTISER_NAME }) => ({
          label: ADVERTISER_NAME,
          value: ADVERTISER_NAME
        }));
        clients.push({ label: Constants.BLANK, value: Constants.BLANK });
        setClientData(clients);
      } else {
        toast.error(
          "There was an error in fetching clients",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching clients - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getConnections = async () => {
    try {
      const connectionsResult = await api.getConnections();
      if (connectionsResult) {
        const connections = connectionsResult.map(({ PUBLISHER_NAME }) => ({
          label: PUBLISHER_NAME,
          value: PUBLISHER_NAME
        }));
        connections.push({ label: Constants.BLANK, value: Constants.BLANK });
        setConnectionData(connections);
      } else {
        toast.error(
          "There was an error in fetching clients",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      toast.error(
        `There was an error in fetching clients - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const getAudiences = async (client = [], platform = "", mainSearch = "") => {
    try {
      setUIState(Constants.UI_STATE_LOADING);
      let input = {
        pageOffset,
        pageSize,
        sortColumn: tableSort[0].id,
        sortDescending: tableSort[0].desc
      };
      input.clientFilter = client.length ? client : "";
      input.publisherFilter = platform;
      input.mainFilter = mainSearch;
      const result = await api.getAudiences(input);

      if (result) {
        if (result.audiences && result.audiences.length) {
          setUIState(Constants.UI_STATE_SUCCESS);
          setAudiencesTableData(result.audiences);
          setTotalAudienceCount(result.totalCount);
          setTotalPageCount(Math.floor(result.totalCount / pageSize));
        } else {
          setUIState(Constants.UI_STATE_EMPTY);
          setTotalAudienceCount(result.totalCount);
        }
      } else {
        setUIState(Constants.UI_STATE_ERROR);
        toast.error(
          "There was an error in fetching audiences",
          Constants.TOAST_OPTIONS
        );
      }
    } catch (ex) {
      setUIState(Constants.UI_STATE_ERROR);
      /* eslint-disable no-console */
      console.log(ex);
      // Ignore the exception, this means we did not get the data
      toast.error(
        `There was an error in fetching audiences - ${ex.message}`,
        Constants.TOAST_OPTIONS
      );
    }
  };

  const filterAudienceData = async () => {
    // When we make actual API call along with response will add
    // Client name as "(Blank)"
    // The string value "(Blank)" will be used to filter empty connection.
    const client =
      clientSearchPayload === Constants.BLANK ? "" : clientSearchPayload;
    const platform =
      connectionSearchPayload === Constants.BLANK
        ? ""
        : connectionSearchPayload;
    await getAudiences(client, platform, audienceSearchPayload);
  };

  useEffect(() => {
    getAudiences();
    getClients();
    getConnections();
  }, []);

  /* Side effect when search term updates */
  useEffect(() => {
    filterAudienceData();
  }, [
    audienceSearchPayload,
    clientSearchPayload,
    connectionSearchPayload,
    pageOffset,
    pageSize,
    tableSort
  ]);

  return (
    <div ref={appRef} className="audience-list-page audiencesClass full-height">
      {/* Action Bar */}
      <div className="audience-list-page__action-bar">
        {/* Title and Search */}
        <span className="display-flex audience-list-page__action-bar__left">
          <h5 className="audience-list-page__action-bar__title dark-gray">
            Merkury Audience Usage
          </h5>
        </span>
        <div className="audience-list-page__action-bar__search-wrapper">
          <SelectBox
            id="clientId"
            name="client"
            placeholder="Select Client"
            className="form-control audience-list-page__action-bar__input p-0"
            options={clientData}
            onSelectHandler={setClientSearchPayload}
            isMultiSelect
          />
          <SelectBox
            id="connectionId"
            name="connection"
            placeholder="Select Connection"
            className="form-control audience-list-page__action-bar__input p-0"
            options={connectionData}
            onSelectHandler={setConnectionSearchPayload}
          />
        </div>
      </div>
      <div className="audience-list-page__action-bar">
        <span className="audience-list-page__action-bar__title dark-gray margin-btm-0">
          Dentsu Media US: Merkury Audience Usage Summary
        </span>
      </div>
      {/* Audiences Table */}
      <div className="card audience-list-page__table-wrapper">
        <span className="display-flex audience-list-page__action-bar__left mt-2">
          <h5 className="audience-list-page__action-bar__title margin-btm-0 audience-counter">
            Total {totalAudienceCount} entries
          </h5>
          <div className="audience-list-page__action-bar__search-wrapper">
            <ExportLink setIsExportDone={setIsExportDone} />
            <input
              type="text"
              placeholder="Search"
              id="searchAudiences"
              className="form-control audience-list-page__action-bar__input rounded-pill"
              onChange={(e) =>
                debouncedHandleAudienceSearchTermChange(e.target.value)
              }
            />
            {!audienceSearchPayload.length ? (
              <i className="fas fa-search align-absolute-vertical-center" />
            ) : (
              <i
                className="far fa-times-circle align-absolute-vertical-center clear-input-btn"
                onClick={() => clearSearchInput()}
              />
            )}
          </div>
        </span>
        <hr />
        <AudiencesTable
          uiState={uiState}
          originalData={audiencesTableData}
          totalPageCount={totalPageCount}
          totalCount={totalAudienceCount}
          setTablePageSize={setTablePageSize}
          setTablePageOffset={setTablePageOffset}
          setTableSort={setTableSort}
        />
      </div>
      {(isChangingClient || !isExportDone) && (
        <ProgressOverlay message={changeMessage} />
      )}
    </div>
  );
};

export default AudienceListPage;
