import Constants from "../common/Constants";

/**
 * Security script, to hold handy user permission checks.
 *
 * Note: with a session, the index script should set the user object to the window.
 * A valid user object will have a 'groups' array property with allowed permission groups.
 */

function checkPermission(perm) {
  // check for the user on the window
  let winUser = window.userSession;
  if (!winUser || !winUser.groups) return false;

  // map to avoid array scans for performance
  if (!winUser.groupMap) {
    winUser.groupMap = winUser.groups.reduce(
      (agg, group) => (agg[group] = true) && agg,
      {}
    );
  }

  // return if the property is there
  return winUser.groupMap[perm] || false;
}

export default {
  // Super User
  isAdmin: () => checkPermission(Constants.ADMIN_GROUP),
  // Overall Merkury Usage permissions
  canViewAudiences: () => checkPermission(Constants.MERKURY_USAGE_GROUP)
};
