import React, { useMemo, useState } from "react";
import { useTable, useRowSelect, useSortBy } from "react-table";

import Constants from "../../common/Constants";
import BackdropLoader from "../shared/BackdropLoader";

const Table = ({ data, selectedRow, setSelectedRow, uiState }) => {
  // Custom sorting handler for columns
  const sortName = (rowA, rowB) =>
    rowA.values[Constants.COL_MERKLE_ADVERTISER_NAME]
      .toLowerCase()
      .localeCompare(
        rowB.values[Constants.COL_MERKLE_ADVERTISER_NAME].toLowerCase()
      );

  const columns = useMemo(
    () => [
      {
        accessor: "displayName",
        Header: "Name",
        sortType: sortName
      },
      {
        accessor: "email",
        Header: "Email"
      },
      {
        accessor: "status",
        Header: "Status"
      },
      {
        accessor: "groups",
        Header: "Admin",
        Cell: ({ row }) => {
          const isAdmin =
            row.values.groups &&
            row.values.groups.includes(Constants.ADMIN_GROUP);
          if (isAdmin) {
            return <i className="fas fa-check"></i>;
          }
          return <></>;
        }
      }
    ],
    [data]
  );

  const getHeaderClassName = (header) => {
    switch (header) {
      case "Select":
        return "col-select";
      case Constants.COL_NAME:
        return "col-name";
      case Constants.COL_EMAIL:
        return "col-email";
      case Constants.COL_STATUS:
        return "col-audience-id";
      case Constants.COL_ADMIN:
        return "col-admin";
      default:
        return "";
    }
  };

  const centerAlignedColumns = ["Select", Constants.COL_ADMIN];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          {
            Header: "Select",
            Cell: ({ row }) => {
              return (
                <input
                  type="radio"
                  name="user-row"
                  onClick={() => setSelectedRow(row.original)}
                />
              );
            }
          },
          ...columns
        ]);
      }
    );

  const getTableBodyTemplate = () => {
    switch (uiState) {
      case Constants.UI_STATE_LOADING:
        return (
          <tr>
            <td colSpan={12} className="pos-relative loader-wrapper">
              <BackdropLoader />
            </td>
          </tr>
        );
      case Constants.UI_STATE_EMPTY:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">No Users available</h5>
            </td>
          </tr>
        );
      case Constants.UI_STATE_ERROR:
        return (
          <tr>
            <td colSpan={12}>
              <h5 className="placeholder text-center">Error fetching User</h5>
            </td>
          </tr>
        );
      default:
        if (rows.length === 0) {
          return (
            <tr>
              <td colSpan={12}>
                <h5 className="placeholder text-center">No Users available</h5>
              </td>
            </tr>
          );
        }
        return rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td
                  {...cell.getCellProps()}
                  style={{
                    textAlign: centerAlignedColumns.includes(cell.column.Header)
                      ? "center"
                      : "left"
                  }}
                >
                  <span
                    className={
                      centerAlignedColumns.includes(cell.column.Header)
                        ? "center-align-cell"
                        : "audience-name__cell"
                    }
                  >
                    {cell.render("Cell")}
                  </span>
                </td>
              ))}
            </tr>
          );
        });
    }
  };

  return (
    <div className="table-container">
      <table className="audiences-table table-full-width" {...getTableProps()}>
        <thead className="fixed-header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={`${getHeaderClassName(column.Header)}`}
                  style={{
                    textAlign: centerAlignedColumns.includes(column.Header)
                      ? "center"
                      : "justify"
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>{getTableBodyTemplate()}</tbody>
      </table>
    </div>
  );
};

export default Table;
