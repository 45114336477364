import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useOktaAuth, LoginCallback } from "@okta/okta-react";

import Constants from "./common/Constants";
import Cookies from "./helpers/Cookies";
import OktaLoginPage from "./OktaLoginPage";
import TopNav from "./components/TopNav";
import AudienceList from "./components/AudienceListPage";
import ChangeLog from "./components/ChangeLog";

import UserManager from "./components/users/UserManager";

import ProtectedRoute from "./ProtectedRoute";
import packageJson from "../package.json";

import security from "./services/Security";
import dentsuLogo from "./assets/logo.png";

const ErrorPage = () => {
  const location = useLocation();
  const errorMessage = location.state && location.state.errorMessage;

  return (
    <div id="main-display">
      <div className="row ml-2">
        <h4 className="red-text">Login Error</h4>
      </div>
      <div className="row ml-2">
        <b>{errorMessage}</b>
      </div>
      <br />
      <div className="row ml-2">Please contact Merkury Usage support</div>
    </div>
  );
};

const BasePage = ({
  isAuthenticated,
  isSessionSet,
  oktaAuthConfig,
  setSession
}) => {
  const canViewAudiences = security.canViewAudiences();

  return (
    <div id="main-display">
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canViewAudiences}
              routeName="Audience List"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <AudienceList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-manager"
          element={
            <ProtectedRoute
              isLoggedIn={isAuthenticated}
              isSessionSet={isSessionSet}
              hasPermissions={canViewAudiences}
              routeName="Audience List"
              oktaAuthConfig={oktaAuthConfig}
              setSession={setSession}
            >
              <UserManager />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/login"
          element={
            !isAuthenticated || !isSessionSet ? (
              <OktaLoginPage config={oktaAuthConfig} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route exact path="/error" element={<ErrorPage />} />
        <Route path="callback" element={LoginCallback} />
        <Route
          exact
          path="/changeLog"
          element={
            isAuthenticated || isSessionSet ? (
              <ChangeLog versionNumber={packageJson.version} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </div>
  );
};

BasePage.propTypes = {
  oktaAuthConfig: PropTypes.shape({
    issuer: PropTypes.string,
    redirectUri: PropTypes.string,
    clientId: PropTypes.string
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSessionSet: PropTypes.bool.isRequired,
  setSession: PropTypes.func.isRequired
};

const BaseRouter = ({ isSessionSet, oktaAuthConfig, setSession }) => {
  // Get the App Version No.
  const versionNumber = packageJson.version;
  const { authState } = useOktaAuth();

  let isAuthenticated = false;
  let isLoggedIn = false;

  if (authState && authState.isAuthenticated) {
    isAuthenticated = authState.isAuthenticated && isSessionSet;
    isLoggedIn = authState.isAuthenticated;
  } else {
    // Check for cookie value
    const accessTokenVal = Cookies.getCookie(Constants.APP_LOGIN_KEY);
    const accessTokenValAppend = Cookies.getCookie(
      Constants.APP_LOGIN_KEY_APPEND
    );
    const loggedinUser = Cookies.getCookie(Constants.APP_LOGIN_USER);
    if (accessTokenVal && accessTokenValAppend && loggedinUser) {
      isAuthenticated = isSessionSet;
      isLoggedIn = true;
    }
  }
  return (
    <div className="app-content-wrapper">
      {isAuthenticated && <TopNav />}

      <BasePage
        oktaAuthConfig={oktaAuthConfig}
        isAuthenticated={isLoggedIn}
        isSessionSet={isSessionSet}
        setSession={setSession}
      />
      {isAuthenticated && (
        <div id="footer">
          <a href="/#/changeLog">
            <i>Version {versionNumber}</i>
          </a>
          <div className="float-right">
            <img
              src={dentsuLogo}
              height="15"
              className="img-responsive"
              alt="dentsu logo"
            />
          </div>
        </div>
      )}
    </div>
  );
};

BaseRouter.propTypes = {
  oktaAuthConfig: PropTypes.shape({
    issuer: PropTypes.string,
    redirectUri: PropTypes.string,
    clientId: PropTypes.string
  }).isRequired,
  isSessionSet: PropTypes.bool.isRequired,
  setSession: PropTypes.func.isRequired
};

export default BaseRouter;
