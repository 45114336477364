/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import OktaLogout from "../OktaLogout";
import Dropdown from "./shared/Dropdown";
import logo from "../assets/logo.png";
import security from "../services/Security";

export default class TopNav extends React.PureComponent {
  render() {
    // Get email address from the user session
    const emailAddress =
      window.userSession && window.userSession.email
        ? window.userSession.email
        : "";
    const isAdmin = security.isAdmin();

    return (
      <div className="navbar-container">
        <div id="navbar" className="navbar" role="navigation">
          <div className="row">
            <a href="/#/" className="home-icon-section">
              <i className="fa fa-home" />
            </a>{" "}
            <div className="app-header-name">
              <a href="/#/">
                <img
                  src={logo}
                  height="30"
                  className="img-responsive"
                  alt="dentsu logo"
                />
              </a>
            </div>
            {isAdmin && (
              <div className="app-header-menuitem">
                <a href="/#/user-manager">User Management</a>
              </div>
            )}
          </div>
          <div className="nav">
            <Dropdown defaultText="English (UK)">
              <li>
                <a className="dropdown-item" href="/#/">
                  English (UK)
                </a>
              </li>
            </Dropdown>
            <li>
              <a className="nav-icon" href="/#/">
                <i className="fas fa-bell fa-lg" />
              </a>
            </li>
            <li>
              <a className="nav-icon" href="/#/">
                <i className="fas fa-question fa-lg circle-icon" />
              </a>
            </li>
            <Dropdown>
              <li>
                <a className="dropdown-item">{emailAddress}</a>
              </li>
              <li>
                <a
                  alt="Change Password"
                  className="dropdown-item"
                  href="https://myapps.dentsu.com/signin/forgot-password"
                  target="_blank"
                  title="Change Password"
                  rel="noreferrer"
                >
                  <i className="fas fa-key" />
                  {` `}
                  Change Password
                </a>
              </li>
              <li>
                {/* TODO : This link need to be changed later */}
                <a
                  alt="Report Bug"
                  href="https://dentsu-msl.atlassian.net/servicedesk/customer/portal/18/group/71/create/139"
                  target="_blank"
                  title="Report Bug"
                  rel="noreferrer"
                  className="dropdown-item"
                >
                  <i className="fas fa-bug fa-lg" />
                  {` `}
                  Report Bug
                </a>
              </li>
              <li>
                <OktaLogout />
              </li>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}
