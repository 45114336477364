/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Dropdown = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [showClass, setShowClass] = useState("");

  useEffect(() => {
    if (isOpen) {
      setShowClass("show");
    } else {
      setShowClass("");
    }
  }, [isOpen]);

  return (
    <span className="dropdown">
      <a
        className={`nav-icon dropdown-toggle ${showClass}`}
        onClick={() => setOpen(!isOpen)}
      >
        {props.defaultText ? (
          props.defaultText
        ) : (
          <img
            src="https://placehold.co/40"
            alt="Avatar Logo"
            className="rounded-pill"
            width="30"
          />
        )}
      </a>
      <ul className={`dropdown-menu dropdown-menu-right ${showClass}`}>
        {props.children}
      </ul>
    </span>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  defaultText: PropTypes.string
};

Dropdown.defaultProps = {
  defaultText: ""
};

export default Dropdown;
