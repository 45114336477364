import { Zoom } from "react-toastify";

// Authentication related constants
const APP_LOGIN_KEY = "appLoginKey";
const APP_LOGIN_KEY_APPEND = "appLoginKeyAppend";
const APP_LOGIN_USER = "appLoginUser";
const APP_SESSION_KEY = "appSessionKey";

const UI_STATE_LOADING = "LOADING";
const UI_STATE_SUCCESS = "SUCCESS";
const UI_STATE_ERROR = "ERROR";
const UI_STATE_EMPTY = "EMPTY";
const STD_MSG_ERROR = "Something Went Wrong";
const STD_MSG_EMPTY = "Not Available";
const ERR_SESSION = "Session not found";
const API_ROOT_URL = process.env.API_GATEWAY_URL;
const TOAST_OPTIONS = {
  autoClose: 2000,
  transition: Zoom
};

const BLANK = "(Blank)";
const COL_PUBLISH_DATE = "PUBLISH_DATE_TIME";
const COL_MERKLE_ADVERTISER_NAME = "ADVERTISER_NAME";
const COL_MERKLE_ADVERTISER_ID = "ADVERTISER_ID";
const COL_AUDIENCE_ID = "PUBLISHED_AUDIENCE_ID";
const COL_AUDIENCE_NAME = "PUBLISHED_AUDIENCE_NAME";
const COL_AUDIENCE_VERSION = "VERSION";
const COL_MERKLE_CONNECTION_ID = "PUBLISHER_ID";
const COL_MERKLE_CONNECTION_NAME = "PUBLISHER_NAME";
const COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID = "PUBLISHER_ACCOUNT";
const COL_SAVED_AUDIENCE_SIZE = "SAVED_AUDIENCE_SIZE";
const COL_PUBLISHED_AUDIENCE_SIZE = "PUBLISHED_AUDIENCE_SIZE";
const DENTSU_COOKIE = "X-dentsu-application";

const FORM_FIELDS = {
  name: "displayName",
  email: "email",
  groups: "groups",
  status: "enabled"
};

const COL_NAME = "Name";
const COL_EMAIL = "Email";
const COL_STATUS = "Status";
const COL_ADMIN = "Admin";

// USER MANAGER PAGES
const CREATE_USER = "create";
const EDIT_USER = "edit";
const DELETE_USER = "delete";

const ADMIN_GROUP = "merkuryUsage-Admin";
const MERKURY_USAGE_GROUP = "merkuryUsage-AppRole";

export default {
  APP_LOGIN_KEY,
  APP_LOGIN_USER,
  APP_LOGIN_KEY_APPEND,
  APP_SESSION_KEY,
  UI_STATE_LOADING,
  UI_STATE_SUCCESS,
  UI_STATE_ERROR,
  UI_STATE_EMPTY,
  TOAST_OPTIONS,
  STD_MSG_ERROR,
  STD_MSG_EMPTY,
  ERR_SESSION,
  COL_PUBLISH_DATE,
  COL_MERKLE_ADVERTISER_NAME,
  COL_MERKLE_ADVERTISER_ID,
  COL_AUDIENCE_ID,
  COL_AUDIENCE_NAME,
  COL_AUDIENCE_VERSION,
  COL_MERKLE_CONNECTION_ID,
  COL_MERKLE_CONNECTION_NAME,
  COL_MERKLE_CONNECTION_ACCOUNT_NAME_ID,
  COL_NAME,
  COL_EMAIL,
  COL_STATUS,
  COL_ADMIN,
  COL_SAVED_AUDIENCE_SIZE,
  COL_PUBLISHED_AUDIENCE_SIZE,
  API_ROOT_URL,
  DENTSU_COOKIE,
  BLANK,

  FORM_FIELDS,

  ADMIN_GROUP,
  MERKURY_USAGE_GROUP,

  CREATE_USER,
  EDIT_USER,
  DELETE_USER
};
